/** A context that exposes which app modal should be open. */

import { ProductAppKind } from "@/apps/remove-app-button/__generated__/RemoveAppButtonFragment.graphql"
import { ContentAppKind } from "@/apps/util/appConstants"
import React, { useContext, useState } from "react"

export type ActiveAppModalContextValue = {
  openModalState: AppModalState | null
  setOpenModalState: React.Dispatch<React.SetStateAction<AppModalState | null>>
  closeModal: () => void
}

const ActiveAppModalContext = React.createContext({} as ActiveAppModalContextValue)

export function useActiveAppModal() {
  return useContext(ActiveAppModalContext)
}

export type AppModalKind = "add-app" | ProductAppKind
export type AppIconKind =
  // DiscoIcon
  | "widget-alt"
  | "file-alt"
  | "calendar"
  | "user"
  | "layer"
  | "layers-three"
  | "chat-alt"
  | "code"
  | "page"
  | "link"
  | "external-link"
  | "3d-box"
  | "user-circle"
  | "pound"
  | "book-open"
  | "message"
  | "at-sign"
  | "discussion"
  | "book"
  | "grid"
  | "group-share"
  | "user-circle"
  // AppIcon
  | "icon-discord"
  | "icon-airtable"
  | "icon-google-doc"
  | "icon-google-form"
  | "icon-typeform"
  | "icon-whereby"
  | "icon-figma"
  | "icon-miro"
  | "icon-page"
  | "icon-slack"
  | "folder"
  | "icon-scorm"

export interface AppModalState {
  id?: string
  kind: AppModalKind | null
  contentAppKind?: ContentAppKind
}

export const ActiveAppModalProvider: React.FC = ({ children }) => {
  const [openModalState, setOpenModalState] = useState<AppModalState | null>(null)

  return (
    <ActiveAppModalContext.Provider
      value={{
        openModalState,
        setOpenModalState,
        closeModal: () => {
          setOpenModalState(null)
        },
      }}
    >
      {children}
    </ActiveAppModalContext.Provider>
  )
}
