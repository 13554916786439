/**
 * @generated SignedSource<<5dfd892c90ea928aa8d873fe3d958e27>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type BadgeKind = "emoji" | "icon" | "upload" | "%future added value";
export type DashboardLayout = "one_column" | "two_column" | "%future added value";
export type FeedAllowPostsFrom = "admins" | "all" | "groups" | "%future added value";
export type ProductAppKind = "chat_channel" | "collection" | "content" | "curriculum" | "dashboard" | "events" | "group_directory" | "link" | "member_directory" | "members" | "nav_folder" | "organization_events" | "organization_members" | "posts" | "product_link" | "resources" | "slack" | "%future added value";
export type ProductAppVisibility = "admin" | "all" | "membership" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type useEditAppFormStoreFragment$data = {
  readonly appDirectoryItems: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly memberGroup: {
          readonly id: string;
        };
      };
    }>;
  };
  readonly badge: {
    readonly color: string | null;
    readonly emoji: string | null;
    readonly icon: string | null;
    readonly kind: BadgeKind;
    readonly mediaUrl: string | null;
  };
  readonly curriculum: {
    readonly showCompletedUsers: boolean;
  } | null;
  readonly customAppDescription: string | null;
  readonly customAppTitle: string | null;
  readonly customAppUrl: string | null;
  readonly dashboard: {
    readonly layout: DashboardLayout;
  } | null;
  readonly feed: {
    readonly allowPostsFrom: FeedAllowPostsFrom;
    readonly allowPostsGroups: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly id: string;
        };
      }>;
    };
    readonly allowPostsMembers: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly id: string;
        };
      }>;
    };
    readonly name: string;
  } | null;
  readonly id: string;
  readonly kind: ProductAppKind;
  readonly linkProductId: string | null;
  readonly membersDirectoryFilters: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly memberGroupId: string;
      };
    }>;
  };
  readonly navSectionId: string | null;
  readonly showOnDashboard: boolean;
  readonly visibility: ProductAppVisibility;
  readonly visibilityGroups: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
      };
    }>;
  };
  readonly visibilityMembers: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
      };
    }>;
  };
  readonly " $fragmentType": "useEditAppFormStoreFragment";
};
export type useEditAppFormStoreFragment$key = {
  readonly " $data"?: useEditAppFormStoreFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"useEditAppFormStoreFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "kind",
  "storageKey": null
},
v2 = [
  (v0/*: any*/)
],
v3 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "MemberGroupNodeEdge",
    "kind": "LinkedField",
    "name": "edges",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "MemberGroup",
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": (v2/*: any*/),
        "storageKey": null
      }
    ],
    "storageKey": null
  }
],
v4 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "OrganizationMembershipNodeEdge",
    "kind": "LinkedField",
    "name": "edges",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "OrganizationMembership",
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": (v2/*: any*/),
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "useEditAppFormStoreFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "customAppTitle",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "customAppDescription",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "customAppUrl",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "showOnDashboard",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "visibility",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "navSectionId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "linkProductId",
      "storageKey": null
    },
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Curriculum",
      "kind": "LinkedField",
      "name": "curriculum",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "showCompletedUsers",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "AppDirectoryItemNodeConnection",
      "kind": "LinkedField",
      "name": "membersDirectoryFilters",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "AppDirectoryItemNodeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "AppDirectoryItem",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "memberGroupId",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "MemberGroupNodeConnection",
      "kind": "LinkedField",
      "name": "visibilityGroups",
      "plural": false,
      "selections": (v3/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "OrganizationMembershipNodeConnection",
      "kind": "LinkedField",
      "name": "visibilityMembers",
      "plural": false,
      "selections": (v4/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Feed",
      "kind": "LinkedField",
      "name": "feed",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "allowPostsFrom",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "MemberGroupNodeConnection",
          "kind": "LinkedField",
          "name": "allowPostsGroups",
          "plural": false,
          "selections": (v3/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "OrganizationMembershipNodeConnection",
          "kind": "LinkedField",
          "name": "allowPostsMembers",
          "plural": false,
          "selections": (v4/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Badge",
      "kind": "LinkedField",
      "name": "badge",
      "plural": false,
      "selections": [
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "icon",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "color",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "emoji",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "mediaUrl",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Dashboard",
      "kind": "LinkedField",
      "name": "dashboard",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "layout",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "AppDirectoryItemNodeConnection",
      "kind": "LinkedField",
      "name": "appDirectoryItems",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "AppDirectoryItemNodeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "AppDirectoryItem",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "MemberGroup",
                  "kind": "LinkedField",
                  "name": "memberGroup",
                  "plural": false,
                  "selections": (v2/*: any*/),
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ProductApp",
  "abstractKey": null
};
})();

(node as any).hash = "0c2f9b2d55d217d74c5a868e2ac31f91";

export default node;
